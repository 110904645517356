import userFunctions from '@/functions/users'
import { deepCopy } from '@softwear/latestcollectioncore'
import { routes } from '@/router/index'
import { RouteConfig } from 'vue-router'
import consts from '@/store/consts'

interface PageI {
  name: string
  link?: string
  icon?: string
  role?: string
  forbidden?: string
  shortcut?: string
  beta?: boolean
  children?: PageI[]
  unreadCount?: number
}

const pages: PageI[] | RouteConfig[] = [
  {
    name: 'dashboard',
    link: '/dashboard',
    icon: 'mdi-view-dashboard',
    role: 'dashboard-user,sw-admin',
    shortcut: 'Alt+F10',
    beta: true,
  },
  {
    name: 'brand_products',
    link: '/products',
    icon: 'mdi-tag-search',
    shortcut: 'Alt+F1',
  },
  {
    name: 'skus',
    icon: 'mdi-table-edit',
    role: 'products-admin,products-user,products-dataprovider_admin',
    link: '/skueditor',
  },
  {
    name: 'masterdata',
    icon: 'mdi-domain',
    role: 'products-dataprovider_admin,sw-admin',
    link: '/skueditor?masterdata=true&filter=brand=="masterdata"',
  },
  {
    name: 'consumers',
    link: '/consumers',
    icon: 'mdi-account-group',
    role: 'sw,pos-device,owner',
    beta: true,
    shortcut: 'Alt+F4',
  },
  {
    name: 'customers',
    link: '/customers',
    icon: 'mdi-account-group',
    role: 'sales',
    beta: true,
  },
  {
    name: 'receive',
    link: 'https://www.softwearconnect.com/receivings.html#/',
    icon: 'mdi-clipboard-arrow-down',
    role: 'sw',
  },
  { name: 'transit', link: '/transit', icon: 'mdi-transfer', role: 'sw' },
  {
    name: 'sell',
    icon: 'mdi-barcode',
    children: [
      {
        name: 'pos',
        link: 'https://softwear6.softwearconnect.com',
        icon: 'mdi-cash-register',
        role: 'pos-device',
        shortcut: 'Alt+F9',
      },
      {
        name: 'webshop',
        link: 'https://my.shopsettings.com/p/softwear',
        icon: 'mdi-cloud-sync',
        role: 'ecwid_link-show_link',
      },
    ],
  },
  {
    name: 'transactions',
    icon: 'mdi-text-box-search',
    children: [
      {
        name: 'purchase-orders',
        link: '/purchaseorders',
        icon: 'mdi-clipboard-list-outline',
        role: 'products-purchase',
      },
      {
        name: 'receivings',
        link: '/receivings',
        icon: 'mdi-clipboard-arrow-down',
        role: 'logistics-receivings_user',
      },
      {
        name: 'invoices',
        link: '/invoices',
        icon: 'mdi-receipt-text-outline',
        role: 'owner',
      },
      {
        name: 'mutations',
        link: '/transactions?type=mutations',
        icon: 'mdi-package-variant',
        role: 'sw',
      },
      {
        name: 'transits',
        link: '/transit',
        icon: 'mdi-transfer',
        beta: true,
        role: 'owner',
      },
      {
        name: 'sales',
        link: '/transactions?type=sales',
        icon: 'mdi-cash-register',
        role: 'sw',
      },
      {
        name: 'salesOrders',
        link: '/salesorders',
        icon: 'mdi-file-document-outline',
        role: 'sales-admin',
      },
      {
        name: 'inventory',
        link: '/inventory',
        icon: 'mdi-package-variant',
        beta: true,
        role: 'products-admin,products-user',
      },
      {
        name: 'B2CSales',
        link: '/b2csales',
        icon: 'mdi-cash-register',
        beta: true,
        role: 'owner',
      },
    ],
  },
  {
    name: 'reports',
    icon: 'mdi-chart-bar',
    children: [
      {
        name: 'stock',
        link: consts.defaultURLs.STOCK_LINK,
        icon: 'mdi-package-variant',
        role: 'products-report',
        shortcut: 'Alt+F6',
      },
      {
        name: 'articlestatus',
        link: consts.defaultURLs.ARTICLE_STATUS_LINK,
        icon: 'mdi-table-eye',
        role: 'products-report',
        shortcut: 'Alt+F7',
      },
      {
        name: 'topx',
        link: consts.defaultURLs.TOPX_LINK,
        icon: 'mdi-order-alphabetical-descending',
        role: 'products-report',
        shortcut: 'Alt+F8',
      },
      {
        name: 'businessinsight',
        link: consts.defaultURLs.STOCK_INSIGHT_LINK,
        icon: 'mdi-chart-box-plus-outline',
        role: 'products-report',
        beta: true,
      },
      {
        name: 'salespercustomer',
        link: consts.defaultURLs.SALES_PER_CUSTOMER_LINK,
        icon: 'mdi-account-group',
        role: 'owner',
        beta: true,
      },
    ],
  },
  {
    name: 'tenants',
    link: '/tenants',
    icon: 'mdi-account-group',
    role: 'sw',
  },
  {
    name: 'sysadmin',
    icon: 'mdi-auto-fix',
    role: 'sw',
    children: [
      {
        name: 'sizeordering',
        link: '/sizeordering',
        icon: 'mdi-order-numeric-ascending',
        role: 'sw',
      },
    ],
  },
  { name: 'dba', link: '/dba', icon: 'mdi-database-cog', role: 'sw' },
  {
    name: 'help',
    icon: 'mdi-account-question',
    role: 'products',
    children: [
      {
        name: 'teamviewer',
        link: 'https://get.teamviewer.com/latestcollection',
        icon: 'mdi-teamviewer',
      },
      {
        name: 'apidocumentation',
        link: 'https://documenter.getpostman.com/view/10959710/TVsyg5ph',
        icon: 'mdi-api',
      },
      {
        name: 'faq',
        link: '/FAQ',
        icon: 'mdi-frequently-asked-questions',
        role: 'sw',
      },
      {
        name: 'glossary',
        link: '/Glossary',
        icon: 'mdi-frequently-asked-questions',
      },
      {
        name: 'missingbarcode',
        link: '/missingbarcode',
        icon: 'mdi-barcode-off',
        role: '',
        beta: true,
      },
      {
        name: 'keyboardshortcuts',
        link: '/keyboardshortcuts',
        icon: 'mdi-keyboard-settings',
        shortcut: 'F1',
      },
      {
        name: 'documentation',
        link: '/docs',
        icon: 'mdi-file-document-multiple',
        role: 'sw',
      },
      {
        name: 'about',
        link: '/about',
        icon: 'mdi-information',
      },
    ],
  },
  {
    name: 'settings',
    icon: 'mdi-cog',
    children: [
      {
        name: 'preferences',
        link: '/preferences',
        icon: 'mdi-application-cog',
        role: 'products,sw,lc-data-only',
        shortcut: 'Alt+F2',
      },
      {
        name: 'layouts',
        link: '/layouts',
        icon: 'mdi-file-document-edit',
        role: 'owner,sw',
      },
      { name: 'campaigns', link: '/campaigns', icon: 'mdi-sale', role: 'owner,products-dataprovider_admin' },
      { name: 'warehouse', link: '/warehouse', icon: 'mdi-store-cog', role: 'owner,lc-data-only' },
      { name: 'tools', link: '/tools', icon: 'mdi-hammer-wrench', role: 'products,sw', forbidden: 'products-demo' },
      { name: 'eventlog', link: '/eventlog', icon: 'mdi-sine-wave', role: 'products,sw', forbidden: 'products-demo' },
      { name: 'partnertokens', link: '/partnertokens', icon: 'mdi-key-chain', role: 'products-dataprovider_admin,sw', forbidden: 'products-demo' },
    ],
  },
  {
    name: 'inbox',
    link: '/inbox',
    icon: 'mdi-tray-full',
    role: 'sw,products-admin',
    unreadCount: 0,
  },
  {
    name: 'storybook',
    link: '/storybook',
    icon: 'mdi-family-tree',
    role: 'sw',
  },
  {
    name: 'Load balancer',
    icon: 'loadbalancer-icon',
    role: 'sw-admin',
    children: [
      {
        name: 'Tenants',
        link: '/loadbalancer?type=tenants&tenantid=',
        icon: 'mdi-account-box',
      },
      {
        name: 'Servers',
        link: '/loadbalancer?type=servers&serverindex=',
        icon: 'mdi-server',
      },
      {
        name: 'Config',
        link: '/loadbalancer?type=config',
        icon: 'mdi-cog',
      },
    ],
  },
  {
    name: 'account',
    link: '/account',
    icon: 'mdi-account-cog',
    role: 'products',
  },
  {
    name: 'organization',
    link: '/organization',
    icon: 'mdi-office-building-cog',
    role: 'owner,lc-data-only',
  },
  {
    name: 'logout',
    link: '/logout',
    icon: 'mdi-logout',
  },
]

/*
 * Combines routes and main menu items
 * Routes that have no menu correspondence are being added on the top level array
 * This allows us to take advantage of the existing infrastructure that calculates the menu and the route guards rules
 * We clean the routes on the view, removing the routes that are not menu items, so that the main menu is not polluted
 * Now we can add our roles to the router route definitions
 */
const swRoutes = routes.reduce((authRoutes, route) => {
  const pageAndRouteMatchIndex = authRoutes.findIndex((page) => page.link === route.path)
  if (pageAndRouteMatchIndex !== -1) {
    // @ts-ignore
    authRoutes[pageAndRouteMatchIndex] = { ...route, ...authRoutes[pageAndRouteMatchIndex] }
    return authRoutes
  }
  let childPageAndRouteMatchIndeces
  authRoutes.forEach((page, index) => {
    if (page.children) {
      const childIndex = page.children.findIndex((page) => page.link === route.path)
      if (childIndex !== -1) childPageAndRouteMatchIndeces = [index, childIndex]
    }
  })
  // @ts-ignore
  if (childPageAndRouteMatchIndeces?.length === 2) {
    // @ts-ignore
    authRoutes[childPageAndRouteMatchIndeces[0]].children[childPageAndRouteMatchIndeces[1]] = {
      ...route,
      ...authRoutes[childPageAndRouteMatchIndeces[0]].children[childPageAndRouteMatchIndeces[1]],
    }
    return authRoutes
  }
  // fallthrough for routes with no match on the main menu
  // we mark them as `routeOnly` to clean them out when interpreting the main menu
  authRoutes.push({
    ...route,
    link: route.path,
    // @ts-ignore
    routeOnly: true,
  })
  return authRoutes
}, pages)

function authorizedPagesList(userRoles: string[], showBeta: boolean): PageI[] {
  const authorizedPages = []
  swRoutes.forEach((e) => {
    if (e.beta && !showBeta) return
    // blacklisting a page propagates to children
    if (e.forbidden !== undefined && userFunctions.hasRole(userRoles, e.forbidden)) return
    const children = []
    if (e.children) {
      e.children.forEach((c: any) => {
        if (c.beta && !showBeta) return

        if (c.forbidden !== undefined && userFunctions.hasRole(userRoles, c.forbidden)) return
        if (userFunctions.hasRole(userRoles, c.role)) children.push(c)
      })
    }
    if (userFunctions.hasRole(userRoles, e.role)) {
      if (!e.children) authorizedPages.push(e)
      if (e.children && children.length > 0) {
        const newE = deepCopy(e)
        newE.children = children
        authorizedPages.push(newE)
      }
    }
  })
  return authorizedPages
}

export { pages, authorizedPagesList, PageI }
