<template>
  <div>
    <div class="sticky-0 white">
      <v-row>
        <v-col class="mt-3 mb-4 ml-4 mr-0 pr-0" cols="8">
          <h3>{{ swT('shopping_basket') }}</h3>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="mt-3 mb-4" cols="3">
          <v-icon right @click="$store.state.b2bDrawer = false">mdi-close</v-icon>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0">
          <swAuth role="lc-b2b">
            <v-btn style="width: 80%" class="mx-8" large color="blue" dark data-test="checkout">
              <v-icon left>mdi-shopping</v-icon>
              {{ swT('checkout') }}
            </v-btn>
            <template slot="otherwise">
              <v-btn style="width: 80%" class="mx-8" large color="blue" dark data-test="sendOrder" @click="sendOrder">
                <v-icon left>mdi-email-fast-outline</v-icon>
                {{ swT('send_to_erp') }}
              </v-btn>
            </template>
          </swAuth>
        </v-col>
      </v-row>
      <v-divider class="pt-4 mt-4"></v-divider>
    </div>
    <v-container v-if="$store.state.b2bBasketCount == 0">
      <v-row>
        <v-col>
          <h4>
            {{ swT('shopping_basket_empty') }}
          </h4>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row v-for="(warehouse, i) of Object.entries($store.state.b2bBasket)" :key="i">
        <v-col>
          <h3>{{ getWarehouseById(warehouse[0]).name }}</h3>
          <v-divider></v-divider>
          <v-row v-for="(item, j) of Object.entries(warehouse[1])" :key="j">
            <v-col cols="4">
              <v-img :src="imageSrc(imageFromB2bBarcode(Object.keys(item[1])[0]), 200)"></v-img>
            </v-col>
            <v-col cols="8">
              <h5 class="grey--text">{{ getB2bSkuByBarcode(Object.keys(item[1])[0]).brand }}</h5>
              <h5>{{ item[0] }}</h5>
              <h5>{{ getB2bSkuByBarcode(Object.keys(item[1])[0]).articleDescription }}</h5>
              {{ Object.values(item[1]).reduce((agg, qty) => agg + qty, 0) }} {{ swT('pieces') }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="mt-0 pt-0">
        <v-col>
          <h4>{{ swT('total_pieces') }}</h4>
          <h3>{{ swT('total') }}</h3>
        </v-col>
        <v-col align="right">
          <h4>{{ $store.state.b2bBasketCount }}</h4>
          <h3>€ {{ $store.state.b2bBasketValue }}</h3>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-divider class="mt-1"></v-divider>
    </v-container>
  </div>
</template>

<script>
import { swT } from '@/functions/i18n'
import globalStore from '@/store/globalStore'
import webServices from '../functions/webServicesFacade'
import { format } from 'date-fns'

import tools from '../functions/tools'
// import swTooltipButton from '../components/swTooltipButton.vue'
import productFunctions from '../functions/products'

export default {
  components: {},
  data() {
    return {
      swT,
    }
  },
  computed: {},
  watch: {},
  methods: {
    getWarehouseById(id) {
      if (!id) return ''
      const warehouses = globalStore.getLatestCollectionObject('warehouse')
      return warehouses[id]
    },
    getB2bSkuByBarcode(barcode) {
      if (!barcode) return ''
      const latestCollectionBrandSkus = globalStore.getB2bSkus()
      return latestCollectionBrandSkus[barcode]
    },
    imageFromB2bBarcode(barcode) {
      if (!barcode) return ''
      const latestCollectionBrandSkus = globalStore.getB2bSkus()
      const sku = latestCollectionBrandSkus[barcode]
      if (!sku) return ''
      const images = sku.images
      if (!images) return ''
      return images.split(',')[0]
    },
    async sendOrder() {
      // If setup for fashionUnited:
      const tenantGln = this.$store.state.user.tenant.gln
      if (!tenantGln) {
        this.$store.dispatch('raiseAlert', {
          header: 'tenant_gln_not_configured',
          type: 'error',
          timeout: 5000,
        })
        return
      }
      const skus = globalStore.getB2bSkus()
      //   const metaBrandSetting = globalStore.getLatestCollectionObject('metabrandsetting')

      Object.entries(this.$store.state.b2bBasket).forEach(([wh, order]) => {
        console.log(wh)
        const ediOrder = []
        Object.entries(order).forEach((entry) => {
          const lines = entry[1]
          Object.entries(lines).forEach(([barcode]) => {
            ediOrder.push(skus[barcode])
          })
        })
        const pricat = productFunctions.skusToPricat(ediOrder, '8712423008403', tenantGln, '1234567890104', 123, format(new Date(), 'yyyyMMdd'))
        const csvPricat = productFunctions.pricatToCsv(pricat)
        // tools.saveStringAsFile('latestCollection.pri', csvPricat, 'text/csv')

        console.log('>>>>>>>>>>>>>>>>>>>>>>')
        console.log(csvPricat)
      })
    },
    imageSrc(imageUrl, imageWidth) {
      return tools.imageUrlParse(imageUrl, imageWidth)
    },
  },
}
</script>
