<template>
  <v-app>
    <div v-if="$route.name !== 'login' && $route.name !== 'signup'">
      <nav>
        <v-app-bar app flat style="opacity: 0.96; z-index: 6;" :class="hazard">
          <v-toolbar-title>
            <span v-if="!$store.state.drawer">
              <v-img width="90px" contain src="./assets/lclogotr.png" @click="$store.state.drawer = true"></v-img>
            </span>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <!-- App Filter -->
          <v-text-field
            v-if="appFilterMode"
            v-show="$store.state.authenticated"
            v-model="$store.state.rawFilter"
            outlined
            background-color="white"
            clearable
            hide-details
            dense
            fab
            data-test="app-filter"
            :label="$store.state.mainFilterPrompt"
            prepend-inner-icon="mdi-magnify"
            class="px-1"
          ></v-text-field>
          <span v-show="!$store.state.loading && $store.state.skuLoading">
            <v-progress-circular :width="2" :size="20" color="grey darken-1" indeterminate></v-progress-circular>
          </span>

          <!-- Product picker -->
          <v-autocomplete
            v-if="productPickerMode"
            ref="productPicker"
            v-model="$store.state.productPicker"
            outlined
            background-color="white"
            clearable
            open-on-clear
            hide-details
            dense
            fab
            data-test="productPicker"
            class="px-1 pr-0"
            :label="`${swT('search_by')} ${swT('sku.' + $store.state.appFilterBySelectedKey)}`"
            :items="$store.state.productPickerItems"
            :item-text="$store.state.appFilterBySelectedKey"
            :item-value="$store.state.appFilterBySelectedKey"
          >
            <template v-slot:item="data">
              <template>
                <v-card elevation="2" class="ma-2 ml-n2">
                  <v-expand-transition appear>
                    <v-img v-if="data.item.IMAGES && data.item.IMAGES.length > 0" :src="imageSrc(data.item.IMAGES[0].url, 150)" max-width="75" />
                  </v-expand-transition>
                </v-card>
                <v-list-item-content>
                  <v-list-item-title class="blue--text" v-html="data.item[$store.state.appFilterBySelectedKey]"></v-list-item-title>
                  <v-list-item-subtitle v-if="$store.state.appFilterBySelectedKey !== 'articleCodeSupplier'">{{ data.item.articleCodeSupplier }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="$store.state.appFilterBySelectedKey !== 'articleGroup'">{{ data.item.articleGroup }}</v-list-item-subtitle>
                  <div v-if="$store.state.appFilterBySelectedKey !== 'articleDescription'">
                    {{ data.item.articleDescription }}
                  </div>
                </v-list-item-content>
              </template>
            </template>
            <template v-slot:append class="d-flex justify-center">
              <v-img
                v-if="['/receivings', '/inventory', '/products'].includes($route.path)"
                :width="$vuetify.breakpoint.smAndDown ? '20px' : '35px'"
                contain
                src="./assets/barcode-logo.png"
                style="filter: grayscale(1); cursor: pointer"
                data-test="activateBarcodeMode"
                class="mr-2"
                :small="$vuetify.breakpoint.smAndDown"
                @click="switchToBarcodeMode"
              ></v-img>
            </template>
            <template v-slot:prepend-inner>
              <template>
                <v-menu left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" :small="$vuetify.breakpoint.smAndDown" v-on="on">mdi-menu</v-icon>
                  </template>

                  <v-list>
                    <v-list-item v-for="property in $store.state.appFilterByList" :key="property" class="d-flex align-center">
                      <div :class="$store.state.appFilterBySelectedKey === property ? 'blue--text' : ''" @click="$store.state.appFilterBySelectedKey = property">
                        {{ swT('sku.' + property) }}
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </template>
          </v-autocomplete>

          <!-- Barcode scanner -->
          <v-text-field
            v-if="barcodeScannerMode"
            ref="barcodeScanner"
            v-model="barcode"
            outlined
            background-color="white"
            clearable
            hide-details
            prepend-inner-icon="mdi-magnify"
            dense
            fab
            label="Scan barcode"
            data-test="scanBarcodeTextField"
            @paste="pasteBarcode()"
            @keyup.enter="scanBarcode()"
          >
            <template v-slot:append>
              <v-tooltip v-if="$route.path === '/products'" v-model="showSwitchedToBarcodeModeToolTip" bottom color="warning">
                <!-- eslint-disable-next-line vue/no-unused-vars -->
                <template v-slot:activator="{ on, attrs }">
                  <v-img
                    width="35px"
                    contain
                    data-test="deactivateBarcodeMode"
                    src="./assets/barcode-logo.png"
                    style="cursor: pointer"
                    @load="showTooltip"
                    @click="switchToAppFiterMode"
                  ></v-img>
                </template>
                <span>{{ swT('swiched_to_barcode_mode') }}</span>
              </v-tooltip>
              <v-img v-else width="35px" contain data-test="deactivateBarcodeMode" src="./assets/barcode-logo.png" style="cursor: pointer" @click="switchToProductMode"></v-img>
            </template>
          </v-text-field>

          <v-spacer></v-spacer>

          <swInfo v-if="$store.state.info.data.length" />

          <swSaveCancel v-if="$store.state.editing" />
          <div v-show="!$store.state.authenticated">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn style="min-width: 26px !important" color="white" small v-bind="attrs" v-on="on">
                  {{ language }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in languages" :key="index" @click="$vuetify.lang.locale = language = item.code">
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <swAuth role="lc-b2b,lc-data-only">
            <v-icon v-if="$store.state.b2bBasketCount == 0" left class="ma-0" @click="$store.state.b2bDrawer = !$store.state.b2bDrawer">mdi-shopping-outline</v-icon>
            <v-icon v-else left class="ma-0" @click="$store.state.b2bDrawer = !$store.state.b2bDrawer">mdi-shopping</v-icon>
          </swAuth>

          <v-app-bar-nav-icon v-if="!$store.state.editing" data-test="hamburger" @click="toggleDrawer"></v-app-bar-nav-icon>
        </v-app-bar>
      </nav>

      <v-navigation-drawer v-model="$store.state.drawer" v-hotkey="keymap()" data-test="drawer" class="sw-grey" app fixed>
        <v-subheader class="white display-1 thin py-12" style="height: 128px">
          <v-img src="./assets/lclogo.png" @click="$store.state.drawer = false"></v-img>
        </v-subheader>
        <div v-if="$store.state.user.settings.user.username" class="username pt-4 px-4 text-h6 text-truncate">{{ $store.state.user.settings.user.username }}</div>
        <swAuth role="sw">
          <v-switch v-if="!$store.state.editing" v-model="showRoles" label="Show roles" dark dense hide-details class="pa-0 pl-2"></v-switch>
        </swAuth>
        <v-treeview
          v-if="$store.state.authenticated && !$store.state.editing"
          v-model="tree"
          class="drawer__treeview pt-2"
          dark
          :open="open"
          :items="menuItems"
          :dense="menuItems.length > 10"
          animation
          item-key="name"
          transition
          open-on-click
        >
          <template v-slot:label="{ item }">
            <a v-if="item.link && item.link.slice(0, 4) == 'http'" class="white--text" :href="item.link" target="_blank">
              <swNavigationItemText :item="item" :show-roles="showRoles"></swNavigationItemText>
            </a>
            <router-link v-else-if="item.link" class="white--text" :to="item.link" :data-test="item.name">
              <swNavigationItemText :item="item" :show-roles="showRoles"></swNavigationItemText>
            </router-link>
            <span v-else :data-test="item.name">
              <swNavigationItemText :item="item" :show-roles="showRoles"></swNavigationItemText>
            </span>
          </template>
        </v-treeview>

        <div class="py-0 ma-0 px-2 caption">
          <v-progress-linear v-model="percentageBarcodesUsed" buffer-value="100" color="error" background-color="success" rounded></v-progress-linear>
          {{ percentageBarcodesUsed }} % of {{ maxBarcodes }} barcodes used
        </div>
      </v-navigation-drawer>

      <swAuth role="lc-b2b,lc-data-only">
        <v-navigation-drawer v-model="$store.state.b2bDrawer" fixed app right width="320">
          <swShoppingBagDrawer></swShoppingBagDrawer>
        </v-navigation-drawer>
      </swAuth>
      <v-main class="mx-0">
        <v-overlay :value="$store.state.loading">
          <v-img src="./assets/loading/Loading_Barcode.gif" contain max-height="80vh"></v-img>
        </v-overlay>
        <div class="main-height">
          <v-expand-transition>
            <div v-if="$store.state.info.showInfoDrawer">
              <div v-for="(info, id) in $store.getters.prioritizedInfo" :key="id">
                <v-card v-if="info.component">
                  <v-card-text>
                    <component :is="info.component"></component>
                  </v-card-text>
                </v-card>
                <v-card v-if="info.drawerContent" tile text>
                  <v-card-title primary-title>
                    {{ info.title }}
                  </v-card-title>
                  <v-card-text v-html="info.drawerContent"></v-card-text>
                </v-card>
              </div>
            </div>
          </v-expand-transition>
          <v-alert
            v-for="(alert, $alertIndex) in $store.state.alerts"
            :key="$alertIndex"
            v-model="alert.state"
            :text="alert.prominent"
            :prominent="alert.prominent"
            dismissible
            :type="alert.type"
            class="mb-0 pa-5 alert"
            :data-test="`alert-${alert.type}`"
          >
            <b>{{ swT(alert.header) }}</b>
            <p v-if="alert.youtube">
              <iframe :src="alert.youtube" frameborder="0" allowfullscreen></iframe>
            </p>
            <p v-if="alert.image">
              <v-img :src="alert.image"></v-img>
            </p>
            <p v-if="alert.body">{{ swT(alert.body) }}</p>
            <pre v-if="alert.text">{{ alert.text }}</pre>
          </v-alert>

          <!-- 
              To include or exclude Views, You must define the name option in your component's options object, example:
              name: "Receivings"
            -->
          <keep-alive exclude="Reports,Receivings,PurchaseOrders">
            <router-view v-if="$store.state.loadingStatus != 'loading'" :key="$route.fullPath" />
            <div v-else>
              <v-img src="./assets/loading/Loading_Barcode.gif" contain max-height="80vh"></v-img>
            </div>
          </keep-alive>
        </div>

        <div v-if="displayPagination" class="bottom-sticky text-center py-1">
          <v-pagination v-model="$store.state.paginationCurrentPage" :length="$store.state.paginationTotalPages" :total-visible="10" circle></v-pagination>
        </div>
      </v-main>
    </div>
    <div v-else-if="$route.name == 'login'">
      <v-alert v-for="(alert, $alertIndex) in $store.state.alerts" :key="$alertIndex" v-model="alert.state" dismissible :type="alert.type" class="mb-0 pa-5">
        <b>{{ swT(alert.header) }}</b>
        <p v-if="alert.body">{{ swT(alert.body) }}</p>
      </v-alert>
      <Login />
    </div>
    <div v-else-if="$route.name == 'signup'">
      <v-alert v-for="(alert, $alertIndex) in $store.state.alerts" :key="$alertIndex" v-model="alert.state" dismissible :type="alert.type" class="mb-0 pa-5">
        <b>{{ swT(alert.header) }}</b>
        <p v-if="alert.body">{{ swT(alert.body) }}</p>
      </v-alert>
      <SignUp />
    </div>
  </v-app>
</template>

<script>
import Vue, { nextTick } from 'vue'
import userFunctions from './functions/users'
import tools from './functions/tools'
import swNavigationItemText from './components/swNavigationItemText.vue'
import swSaveCancel from './components/swSaveCancel.vue'
import swInfo from './components/swInfo.vue'
import Login from './views/Login.vue'
import SignUp from './views/SignUp.vue'
import { pages } from '@/functions/mainMenu'
import { swT } from '@/functions/i18n'
import language from '@/functions/language'
import consts from '@/store/consts'
import swDateRangePicker from './components/swDateRangePicker.vue'
import globalStore from '@/store/globalStore'
import swShoppingBagDrawer from './components/swShoppingBagDrawer.vue'

export default Vue.extend({
  name: 'App',
  components: { swNavigationItemText, swSaveCancel, Login, SignUp, swInfo, swDateRangePicker, swShoppingBagDrawer },
  data() {
    return {
      swT,
      barcode: '',
      showRoles: false,
      language: this.$store.state.locale,
      languages: consts.allConfig.UISettings.language.items,
      open: ['public'],
      tree: [],
      pages: pages,
      percentageBarcodesUsed: 0,
      showSwitchedToBarcodeModeToolTip: false,
    }
  },

  computed: {
    maxBarcodes() {
      return this.$store.state.user.tenant?.maxBarcodes || 100000
    },
    displayPagination() {
      return this.$store.state.paginationTotalPages > 0
    },
    menuItems() {
      return this.$store.getters.authorizedPagesList.filter((menuItem) => !menuItem.routeOnly)
    },
    hazard() {
      if (userFunctions.hasRole(this.$store.getters.roles, 'sw-admin')) return 'red-hazard'
      if (userFunctions.hasRole(this.$store.getters.roles, 'products-dataprovider_admin')) return 'yellow-hazard'
      if (userFunctions.hasRole(this.$store.getters.roles, 'impersonator')) return 'green-hazard'
      return ''
    },
    productPickerMode() {
      return this.$store.state.productPickerActive && !this.$store.state.barcodeScannerMode && !this.$store.state.hideMainFilter
    },
    barcodeScannerMode() {
      return this.$store.state.productPickerActive && this.$store.state.barcodeScannerMode && !this.$store.state.hideMainFilter
    },
    appFilterMode() {
      return !this.$store.state.productPickerActive && !this.$store.state.hideMainFilter && !this.$store.state.productIsSelected
    },
  },
  watch: {
    '$store.state.b2bBasketUpdateTrigger'() {
      this.$store.state.b2bBasketCount = Object.values(this.$store.state.b2bBasket).reduce(
        (agg0, warehouse) => agg0 + Object.values(warehouse).reduce((agg1, product) => agg1 + Object.values(product).reduce((agg2, qty) => agg2 + qty, 0), 0),
        0
      )
      if (this.$store.state.b2bBasketCount == 0) {
        this.$store.state.b2bBasketValue = 0
        return
      }
      const latestCollectionBrandSkus = globalStore.getB2bSkus()
      this.$store.state.b2bBasketValue = Object.values(this.$store.state.b2bBasket)
        .reduce(
          (agg0, warehouse) =>
            agg0 +
            Object.values(warehouse).reduce(
              (agg1, product) =>
                agg1 +
                Object.entries(product).reduce((agg2, entry) => {
                  const barcode = entry[0]
                  const sku = latestCollectionBrandSkus[barcode] || { buyPrice: 0 }
                  return agg2 + sku.buyPrice
                }, 0),
              0
            ),
          0
        )
        .toFixed(2)
    },
    $route: {
      handler() {
        // This fixes the disappearing app-filter which should be visible when there is no product selected
        if (this.$route.path === '/products' && Object.keys(this.$route.query).length && this.$store.state.selectedProduct?.articleCode) this.$store.state.productIsSelected = true
        else this.$store.state.productIsSelected = false
      },
      immediate: true,
      deep: true,
    },
    '$store.state.paginationTotalPages'(newValue, oldValue) {
      if (oldValue === -1) this.$store.state.paginationCurrentPage = 1
      if (newValue && this.$store.state.paginationCurrentPage > newValue) this.$store.state.paginationCurrentPage = newValue || 1
    },
    '$store.state.totalNrBarcodes'() {
      const nrSkus = this.$store.state.totalNrBarcodes
      const maxBarcodes = this.maxBarcodes
      if (!maxBarcodes) return
      this.percentageBarcodesUsed = Math.min(parseInt((nrSkus / maxBarcodes) * 100), 100)
    },
  },
  mounted() {
    this.$watch('$store.state.productPickerActive', async function(newValue) {
      if (newValue) {
        await nextTick()
        const productPicker = this.$refs.productPicker
        if (this.$store.state.productPickerActive && productPicker) productPicker.focus()
      }
    })

    this.$watch('$store.state.barcodeScannerMode', async function(newValue) {
      if (newValue) {
        await nextTick()
        const barcodeScanner = this.$refs.barcodeScanner
        if (this.$store.state.barcodeScannerMode && barcodeScanner) barcodeScanner.focus()
      }
    })
  },
  activated() {
    this.$store.dispatch('clearFilter')
  },
  async created() {
    document.title = 'Latest Collection'
    const debounceFn = tools.debounce((newVal) => {
      this.$store.state.filter = newVal?.trim()
    }, 500)
    this.$watch('$store.state.rawFilter', debounceFn)
    window.onbeforeunload = this.onExit
    window.addEventListener('keydown', this.keyCommand)
  },
  beforeUpdate() {
    this.checkLanguage()
    this.countUnread()
  },
  methods: {
    countUnread() {
      const menuItems = this.$store.getters.authorizedPagesList.filter((menuItem) => !menuItem.routeOnly)
      menuItems.forEach((menuItem, index) => {
        if (menuItem.unreadCount !== undefined) {
          let unreadCount = 0

          const documents = globalStore.getLatestCollectionArray(menuItem.name)
          documents.forEach((document) => {
            if (document.read == false) unreadCount++
          })
          this.$store.getters.authorizedPagesList[index].unreadCount = unreadCount
        }
      })
    },
    checkLanguage() {
      const savedLanguage = language.checkLanguage(this.$store)
      if (savedLanguage != '' && savedLanguage != undefined) {
        this.language = savedLanguage
      }
    },
    keymap() {
      return {
        f1: this.goToKeyboardShorcuts,
        'alt+f1': this.goToProducts,
        'alt+o': this.goToProductsAsDev, // F keys don't work in cypress
        'alt+f2': this.goToPreferences,
        'alt+f3': this.goToProducts,
        'alt+f4': this.goToCustomers,
        'alt+f6': this.goToStockReport,
        'alt+f7': this.goToArticleStatusReport,
        'alt+f8': this.goToTopXReport,
        'alt+f9': this.goToPOS,
        'alt+f10': this.goToDashboard,
        'alt+f12': this.goToDevTools,
        'ctrl+/': this.toggleDrawer,
      }
    },
    switchToProductMode() {
      this.$store.state.barcodeScannerMode = false
      this.$nextTick(() => {
        this.$refs.productPicker.focus()
      })
    },
    switchToBarcodeMode() {
      this.$store.state.barcodeScannerMode = true
      this.$nextTick(() => {
        this.$refs.barcodeScanner.focus()
      })
    },
    switchToAppFiterMode() {
      this.$store.state.productPickerActive = false
      this.$store.state.barcodeScannerMode = false
      this.$store.state.rawFilter = ''
    },
    pasteBarcode() {
      // The pasted value is not readily available in the binded variable
      // We wait a while before processing
      setTimeout(() => this.scanBarcode(), 20)
    },
    scanBarcode() {
      if (typeof this.barcode == 'string' && [12, 13].includes(this.barcode.length)) {
        this.$store.state.scannedBarcode = this.barcode
        this.barcode = ''
      }
    },
    goToDashboard() {
      if (process.env.NODE_ENV === 'development') this.$router.push({ path: 'dashboard' })
    },
    goToProductsAsDev() {
      if (process.env.NODE_ENV === 'development') this.$router.push({ path: 'products' })
    },
    goToDevTools() {
      this.$router.push({ path: 'dev' })
    },
    goToPOS() {
      window.location.href = 'https://softwear6.softwearconnect.com'
    },
    goToKeyboardShorcuts() {
      this.$router.push({ path: 'keyboardshortcuts' })
    },
    goToProducts() {
      this.$router.push({ path: 'products' })
    },
    goToPreferences() {
      this.$router.push({ path: 'preferences' })
    },
    goToCustomers() {
      this.$router.push({ path: 'consumers' })
    },
    goToArticleStatusReport() {
      this.$router.push({ path: consts.defaultURLs.ARTICLE_STATUS_LINK })
    },
    goToStockReport() {
      this.$router.push({ path: consts.defaultURLs.STOCK_LINK })
    },
    goToTopXReport() {
      this.$router.push({ path: consts.defaultURLs.TOPX_LINK })
    },
    onExit(e) {
      if (!this.$store.state.editing) return
      const dialogText = 'Changes that you made may not be saved.'
      e.returnValue = dialogText
      return dialogText // Modern browsers ignore the text you provide here
    },
    toggleDrawer() {
      this.$store.state.drawer = !this.$store.state.drawer
    },
    keyCommand(e) {
      if (!e.key) return
      const key = e.key.toLowerCase()
      if ((key == '.' || key == 'e') && e.srcElement.inputMode == 'decimal') e.preventDefault()
    },
    imageSrc(imageUrl, imageWidth) {
      return tools.imageUrlParse(imageUrl, imageWidth)
    },
    showTooltip() {
      this.showSwitchedToBarcodeModeToolTip = true
      setTimeout(() => {
        this.showSwitchedToBarcodeModeToolTip = false
      }, 3000)
    },
  },
})
</script>

<style lang="scss">
.main-height {
  min-height: calc(100vh - 116px);
}
.v-application--is-ltr .v-chip .v-chip__close.v-icon.v-icon--right {
  margin-left: 3px;
  margin-right: -4px;
}
.v-chip .v-chip__content {
  margin-left: -4px;
  margin-right: -4px;
}
.v-chip {
  margin-top: 3px !important;
}
.v-input--is-readonly {
  pointer-events: none;
}
*,
html,
body {
  font-family: 'Roboto' !important;
}
html {
  overflow-y: auto;
}
.v-list {
  padding: 0;
}
.v-list-item {
  border-bottom: solid 1px lightgray;
}
h1 {
  font-size: 3rem;
  font-weight: 300;
}
h2 {
  color: #d13326;
}
.red--text {
  color: #d13326 !important;
}
a {
  text-decoration: none;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}
/*** TRANSITIONS ***/
.fade-enter {
  opacity: 0;
  transform: translateX(-100px);
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}
.fade-leave-to {
  opacity: 0;
  transform: translateX(100px);
}
textarea {
  white-space: nowrap;
  overflow: scroll;
}
.drawer__treeview .v-treeview-node__root:hover {
  background-color: #90251dc9;
}
.poView .v-input__slot {
  padding: 0 4px !important;
}
.v-main__wrap .container {
  max-width: 100%;
}
.sizerangeBorder {
  border: 1px solid #cdd4db;
  background-color: #eef1f3;
  font-weight: bold;
  text-align: center;
}
.sw-grey {
  color: #b2bac7 !important;
  background-color: #464d5c !important;
}

.v-menu__content {
  cursor: pointer;
}

.theme--light.v-data-table .v-data-table-header th span {
  display: inline-block;
  max-width: 100%; /* Allow the span to take up 100% width */
  overflow: hidden;
  vertical-align: middle;
  text-overflow: ellipsis; /* Add ellipsis for text overflow */
}

.theme--light.v-data-table .v-data-table-header th {
  white-space: nowrap; /* Prevent text from wrapping by default */
}

.theme--light.v-data-table .v-data-table-header th i {
  margin-left: 3px; /* Add some space between text and icon */
  vertical-align: middle;
}

.theme--light.v-data-table .v-data-table-header th.sortable.active .v-data-table-header__icon,
.theme--light.v-data-table .v-data-table-header__sort-badge,
.theme--light.v-data-table .v-data-table__wrapper table thead tr th,
.theme--light.v-data-table .v-data-table__wrapper table thead tr th.sortable,
.theme--light.v-data-table .v-data-table__wrapper table thead tr th.sortable.active {
  font-weight: 400;
  font-size: 13px;
  background-color: #2196f3;
  color: white !important;
  padding: 0 2px !important;
}
.v-data-table table tbody tr td {
  padding: 0 4px 0 4px !important;
}
.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.strikethrough {
  text-decoration: line-through;
}
input {
  caret-color: #d13326;
}
.sticky {
  @media (max-width: 600px) {
    top: 54px;
  }
  @media (min-width: 600px) {
    top: 62px;
  }
  position: sticky !important;
  z-index: 4;
}
.sticky-0 {
  top: 0px;
  position: sticky !important;
  z-index: 4;
}
.sticky-2 {
  top: 96px;
  position: sticky !important;
  z-index: 4;
}
.sticky-3 {
  top: 128px;
  position: sticky !important;
  z-index: 4;
}
.breadcrumbs {
  top: 64px;
  position: sticky !important;
  z-index: 3;
  &.sm {
    top: 56px;
  }
}
.username {
  color: white;
  overflow: hidden;
  white-space: nowrap;
}
/* Disables spinners for number inputs */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.align-numbers input {
  text-align: right;
}
.bottom-sticky {
  position: sticky;
  bottom: 0px;
  z-index: 4;
  background-color: rgba(11, 11, 11, 0.1);
}
pre {
  font-family: 'Courier New', Courier, monospace !important;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
}
table,
th,
td {
  border: 1px solid lightgrey;
  border-collapse: collapse;
}
.red-hazard {
  background-image: repeating-linear-gradient(-60deg, #fff, #fff 20px, #bb0000 20px, #bb0000 40px);
}
.yellow-hazard {
  background-image: repeating-linear-gradient(-60deg, #444, #555 20px, #ffb101 20px, #ffb101 40px);
}
.green-hazard {
  background-image: repeating-linear-gradient(-60deg, #ddd, #fff 20px, #1b9d05 20px, #1b9d05 40px);
}
.alert {
  z-index: 99999;
}
/*using this class the loadbalancer nav will have the loadbalancer svg */
.loadbalancer-icon {
  content: url(./assets/groupsIcons/loadbalancer.svg);
}
.v-textarea textarea {
  overflow: auto;
}

@media print {
  .v-navigation-drawer,
  .v-toolbar,
  .v-overlay,
  .v-pagination,
  .no-print,
  header {
    display: none;
  }
  .v-main {
    width: 100%;
  }
  @page {
    size: A4 landscape;
  }
  th span {
    color: black !important;
  }

  h1,
  h2 {
    color: black !important;
    font-size: 12pt;
  }
  .no-print {
    display: none;
  }
}
</style>
