import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import routerNavigationGuards from '@/functions/routerNavigationGuards'

Vue.use(VueRouter)

// Lazy load views
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Products.vue'),
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
  },
  { path: '/buy', name: 'Buy', component: () => import('../views/Buy.vue') },
  {
    path: '/campaigns',
    name: 'Campaigns',
    component: () => import('../views/Campaigns.vue'),
  },
  {
    path: '/customers',
    name: 'Customers',
    component: () => import('../views/Customers.vue'),
  },
  {
    path: '/consumers',
    name: 'Consumers',
    component: () => import('../views/Consumers.vue'),
  },
  {
    path: '/b2csales',
    name: 'B2CSales',
    component: () => import('../views/B2CSales.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
  },
  {
    path: '/dba',
    name: 'DBA',
    component: () => import('../views/Dba.vue'),
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('../views/Error.vue'),
  },
  {
    path: '/eventlog',
    name: 'eventlog',
    component: () => import('../views/Eventlog.vue'),
    props: (route) => {
      return { query: route.query }
    },
  },
  {
    path: '/glossary',
    name: 'glossary',
    component: () => import('../views/Glossary.vue'),
    props: (route) => {
      return { query: route.query }
    },
  },
  {
    path: '/inbox',
    name: 'Inbox',
    component: () => import('../views/Inbox.vue'),
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: () => import('../views/Inventory.vue'),
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: () => import('../views/Invoices.vue'),
  },
  {
    path: '/layouts',
    name: 'Layouts',
    component: () => import('../views/Layouts.vue'),
  },
  {
    path: '/organization',
    name: 'Organization',
    component: () => import('../views/Organization.vue'),
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('../views/Products.vue'),
    props: (route) => {
      return { query: route.query }
    },
  },
  {
    path: '/purchaseorders',
    name: 'PurchaseOrders',
    component: () => import('../views/PurchaseOrders.vue'),
    props: (route) => ({ brand: route.query.brand }),
  },
  {
    path: '/receivings',
    name: 'Receivings',
    component: () => import('../views/Receivings.vue'),
    props: (route) => ({ brand: route.query.brand }),
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('../views/Reports.vue'),
    props: (route) => ({ query: route.query }),
  },
  {
    path: '/salesorders',
    name: 'SalesOrders',
    component: () => import('../views/SalesOrders.vue'),
  },
  {
    path: '/skueditor',
    name: 'SkuEditor',
    props: (route) => ({ filter: route.query.filter, redirect: route.query.redirect }),
    component: () => import('../views/SkuEditor.vue'),
  },
  {
    path: '/partnertokens',
    name: 'Partnertokens',
    component: () => import('../views/Partnertokens.vue'),
  },
  {
    path: '/preferences',
    name: 'Preferences',
    component: () => import('../views/Preferences.vue'),
  },
  {
    path: '/sizeordering',
    name: 'SizeOrdering',
    component: () => import('../views/SizeOrdering.vue'),
  },
  {
    path: '/tenants',
    name: 'Tenants',
    component: () => import('../views/Tenants.vue'),
    props: (route) => {
      return { query: route.query }
    },
  },
  {
    path: '/tools',
    name: 'Tools',
    component: () => import('../views/Tools.vue'),
    props: (route) => {
      return { query: route.query }
    },
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: () => import('../views/Transactions.vue'),
    props: (route) => {
      return { query: route.query }
    },
  },
  {
    path: '/transit',
    name: 'Transit',
    component: () => import('../views/Transit.vue'),
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: () => import('../views/Subscribe.vue'),
    props: (route) => {
      return { query: route.query }
    },
  },
  {
    path: '/storybook',
    name: 'Storybook',
    component: () => import('../views/Storybook.vue'),
    props: (route) => {
      return { query: route.query }
    },
  },
  {
    path: '/dev',
    name: 'Developertools',
    component: () => import('../views/dev.vue'),
  },
  {
    path: '/warehouse',
    name: 'Warehouse',
    component: () => import('../views/Warehouse.vue'),
    props: (route) => {
      return { query: route.query }
    },
  },
  {
    path: '/template',
    name: 'template',
    component: () => import('../views/template.vue'),
    props: (route) => {
      return { query: route.query }
    },
  },
  {
    path: '/docs',
    name: 'docs',
    component: () => import('../views/docs.vue'),
    props: (route) => {
      return { query: route.query }
    },
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: () => import('../views/FAQ.vue'),
    props: (route) => {
      return { query: route.query }
    },
  },
  {
    path: '/missingbarcode',
    name: 'missingbarcode',
    component: () => import('../views/Missingbarcode.vue'),
    props: (route) => {
      return { query: route.query }
    },
  },
  {
    path: '/keyboardshortcuts',
    name: 'keyboardshortcuts',
    component: () => import('../views/Keyboardshortcuts.vue'),
    props: (route) => {
      return { query: route.query }
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/Logout.vue'),
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('../views/SignUp.vue'),
    props: (route) => {
      return { query: route.query }
    },
  },
  {
    path: '/matrixeditor',
    name: 'MatrixEditor',
    component: () => import('../views/MatrixEditor.vue'),
    props: (route) => ({ sku: route.query.sku, from: route.query.from }),
  },
  {
    path: '/matrixpoc',
    name: 'matrixpoc',
    component: () => import('../components/swMatrixPOC.vue'),
  },
  { path: '*', name: 'for-o-for', component: () => import('../views/404.vue') },
  {
    path: '/loadbalancer',
    name: 'Loadbalancer',
    component: () => import('../views/loadbalancer/LoadbalancerRouter.vue'),
    props: (route) => {
      return { query: route.query }
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) return { selector: to.hash }
  },
})

router.beforeEach((to, from, next) => {
  routerNavigationGuards(to, from, next)
})

export { routes, router }
